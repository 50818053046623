import React from "react"
import { Link, graphql } from "gatsby"
import { HomeIcon } from '@heroicons/react/20/solid'
import parse from "html-react-parser"

import Layout from "../components/layout.js"
import Seo from "../components/seo"
import List from "../components/ulist"



const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const category = {
    name: post.categories?.nodes[0].name || ``,
    uri: post.categories?.nodes[0].uri,
    chapters: post.categories?.nodes[0].posts.nodes
  }

  const customStyle = {
    /*height: "100vh",
    overflow: "scroll",
    background: "#fff",
    textAlign: "center"*/
  };

  const pages = [
    { name: category.name, href: category.uri, current: false },
    { name: post.title, href: '#', current: true },
  ]
  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} /> 
      <nav className="flex border-b border-gray-200 bg-white" aria-label="Breadcrumb">
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name} className="flex">
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className="border-t-4 border-violet-500 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto ">
        <div className="min-w-0 flex-1 bg-white">
          <div style={customStyle}>
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header className="pb-2 text-center">
                <h1 itemProp="headline" className="pt-8">{parse(post.title)}</h1>
                <p className="text-xs">{post.date}</p>
                <div className="sm:w-1/4 mx-auto sm:py-4 px-2 text-left">
                  <List data={category.chapters} />
                </div>
              </header>

              {!!post.content && (
                <section itemProp="articleBody" className="articleWrap">{parse(post.content)}</section>
              )}

              <hr />

              <footer>
                {/*<Bio />*/}
              </footer>
            </article>
          </div>
          <nav className="blog-post-nav p-4">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.uri} rel="prev">
                    ← {parse(previous.title)}
                  </Link>
                )}
              </li>

              <li>
                {next && (
                  <Link to={next.uri} rel="next">
                    {parse(next.title)} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
        }
      }
      categories {
        nodes {
          name
          uri
          posts {
            nodes {
              title
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
